<template>
  <v-sheet>
    <Dialog :dialog="salaryRemarkDialog" card-text-class="px-4 py-0" :dialogWidth="500">
      <template v-slot:title> <span>Remark </span> <v-spacer></v-spacer></template>
      <template v-slot:body>
        <v-container>
          <template>
            <v-layout class="bg_grey pt-0 pb-0 px-0 my-0">
              <v-row>
                <v-col md="12" class="py-1">
                  <label for="payment-mode" class="field-label">Amount</label>
                  <TextInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    id="payment-mode"
                    @keyup="check(paymentRecordData.amount)"
                    v-model="paymentRecordData.amount"
                    class="mt-0"
                  >
                  </TextInput>
                </v-col>
                <v-col md="12" class="py-1">
                  <label for="notes" class="field-label">Remark</label>
                  <TextAreaInput
                    v-model="remark"
                    auto-grow
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    id="notes"
                    placeholder=""
                  ></TextAreaInput>
                </v-col>
              </v-row>
            </v-layout>
          </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          :disabled="excelLoading"
          :loading="excelLoading"
          v-on:click="saveRecord()"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="excelLoading"
          depressed
          tile
          v-on:click="$emit('close', false)"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import { SET_ERROR } from "@/core/services/store/common.module";

// import { PAY_EMI } from "@/core/lib/pos.lib";

export default {
  name: "item-dialog",
  title: "Dialog Item",

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: null,
    },
    amount: {
      type: [String, Number],
      default: null,
    },
    loan_data: {
      type: [String, Number],
      default: null,
    },
    discount: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      excelLoading: null,
      salaryRemarkDialog: false,
      
      paymentRecordData:{
        amount:0,
        remark:null,
        id:0,

      },
      remark: "",
    };
  },
  methods: {
    check(data){
      
   
      // this.discount = Number(this.discount)-Number(this.amount)
       

 if (Number(data) > Number(this.loan_data)) {
        
       
        this.$store.commit(SET_ERROR, [
          { model: true, message: "The amount not match" },
        ]);
       
        return false;
      }
    },
    async saveRecord() {
   
      this.$emit("amount", this.paymentRecordData.amount);

      this.$emit('success', true)

    },

    
  },
  watch: {
    dialog(params) {
      this.salaryRemarkDialog = params;
      if(this.amount){
        this.paymentRecordData.amount=this.amount
      }
    },
  },
  components: {
    Dialog,
    TextInput,
    TextAreaInput,
  },
};
</script>
<style scoped>
.table.table-sm td {
  padding: 0.6rem !important;
  vertical-align: middle;
}

.table.table-sm th {
  background-color: #ede8e8 !important;
}
</style>
